var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selectGoods"},[_c('nav-bar',{attrs:{"nav-info":_vm.navInfo}}),(_vm.status == 0)?_c('div',{staticClass:"flex1",on:{"click":function($event){return _vm.editInfo(1)}}},[_vm._m(0),_vm._m(1)]):_c('div',{staticClass:"flex1a"},[_vm._m(2),_vm._m(3)]),(_vm.status <= 1)?_c('div',{staticClass:"flex1",on:{"click":function($event){return _vm.editInfo(2)}}},[_vm._m(4),_vm._m(5)]):_c('div',{staticClass:"flex1a"},[_vm._m(6),_vm._m(7)]),(_vm.status <= 2 && _vm.orderStatus==1)?_c('div',{staticClass:"flex1",on:{"click":function($event){return _vm.editInfo(3)}}},[_vm._m(8),_vm._m(9)]):_c('div',{staticClass:"flex1a"},[_vm._m(10),_vm._m(11)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('div',{staticClass:"flex4"},[_c('h2',[_vm._v("起运信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span1"},[_vm._v("发运、交运时的信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span2"},[_vm._v("录入起运信息")]),_c('img',{attrs:{"src":require("../../assets/img/arrow1@3x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('img',{attrs:{"src":require("../../assets/img/departure@3x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('div',{staticClass:"flex4"},[_c('h2',[_vm._v("起运信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span1"},[_vm._v("发运、交运时的信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span2"},[_vm._v("录入起运信息")]),_c('img',{attrs:{"src":require("../../assets/img/arrow1@3x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('img',{attrs:{"src":require("../../assets/img/slices/departure@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('div',{staticClass:"flex4"},[_c('h2',[_vm._v("在途信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span1"},[_vm._v("从出发地到目的地的运输管理过程")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span2"},[_vm._v("录入在途信息")]),_c('img',{attrs:{"src":require("../../assets/img/arrow1@3x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('img',{attrs:{"src":require("../../assets/img/way@3x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('div',{staticClass:"flex4"},[_c('h2',[_vm._v("在途信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span1"},[_vm._v("从出发地到目的地的运输管理过程")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span2"},[_vm._v("录入在途信息")]),_c('img',{attrs:{"src":require("../../assets/img/arrow1@3x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('img',{attrs:{"src":require("../../assets/img/slices/way@2x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('div',{staticClass:"flex4"},[_c('h2',[_vm._v("签收信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span1"},[_vm._v("收件人在指定单据或回执上签字或盖章")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span2"},[_vm._v("录入签收信息")]),_c('img',{attrs:{"src":require("../../assets/img/arrow1@3x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('img',{attrs:{"src":require("../../assets/img/sign@3x.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('div',{staticClass:"flex4"},[_c('h2',[_vm._v("签收信息")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span1"},[_vm._v("收件人在指定单据或回执上签字或盖章")])]),_c('div',{staticClass:"flex4"},[_c('span',{staticClass:"span2"},[_vm._v("录入签收信息")]),_c('img',{attrs:{"src":require("../../assets/img/arrow1@3x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex3"},[_c('img',{attrs:{"src":require("../../assets/img/slices/sign@2x.png"),"alt":""}})])
}]

export { render, staticRenderFns }