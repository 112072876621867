<template>
  <div class="selectGoods">
    <nav-bar :nav-info='navInfo'></nav-bar>
    <div class="flex1" @click="editInfo(1)" v-if="status == 0">
      <div class="flex3">
        <div class="flex4"><h2>起运信息</h2></div>
        <div class="flex4"><span class="span1">发运、交运时的信息</span></div>
        <div class="flex4">
          <span class="span2">录入起运信息</span>
          <img src="../../assets/img/arrow1@3x.png" alt=""/>
        </div>
      </div>
      <div class="flex3"><img src="../../assets/img/departure@3x.png" alt=""/></div>
    </div>
    <div class="flex1a" v-else>
      <div class="flex3">
        <div class="flex4"><h2>起运信息</h2></div>
        <div class="flex4"><span class="span1">发运、交运时的信息</span></div>
        <div class="flex4">
          <span class="span2">录入起运信息</span>
          <img src="../../assets/img/arrow1@3x.png" alt=""/>
        </div>
      </div>
      <div class="flex3"><img src="../../assets/img/slices/departure@2x.png" alt=""/></div>
    </div>

    <div class="flex1" @click="editInfo(2)" v-if="status <= 1">
      <!--    <div class="flex1" @click="editInfo(2)" v-if="1 <= 1">-->
      <div class="flex3">
        <div class="flex4"><h2>在途信息</h2></div>
        <div class="flex4"><span class="span1">从出发地到目的地的运输管理过程</span></div>
        <div class="flex4">
          <span class="span2">录入在途信息</span>
          <img src="../../assets/img/arrow1@3x.png" alt=""/>
        </div>
      </div>
      <div class="flex3"><img src="../../assets/img/way@3x.png" alt=""/></div>
    </div>
    <div class="flex1a" v-else>
      <div class="flex3">
        <div class="flex4"><h2>在途信息</h2></div>
        <div class="flex4"><span class="span1">从出发地到目的地的运输管理过程</span></div>
        <div class="flex4">
          <span class="span2">录入在途信息</span>
          <img src="../../assets/img/arrow1@3x.png" alt=""/>
        </div>
      </div>
      <div class="flex3"><img src="../../assets/img/slices/way@2x.png" alt=""/></div>
    </div>

    <div class="flex1" @click="editInfo(3)" v-if="status <= 2 && orderStatus==1">
      <!--    <div class="flex1" @click="editInfo(3)" v-if="1 <= 2">-->
      <div class="flex3">
        <div class="flex4"><h2>签收信息</h2></div>
        <div class="flex4"><span class="span1">收件人在指定单据或回执上签字或盖章</span></div>
        <div class="flex4">
          <span class="span2">录入签收信息</span>
          <img src="../../assets/img/arrow1@3x.png" alt=""/>
        </div>
      </div>
      <div class="flex3"><img src="../../assets/img/sign@3x.png" alt=""/></div>
    </div>
    <div class="flex1a" v-else>
      <div class="flex3">
        <div class="flex4"><h2>签收信息</h2></div>
        <div class="flex4"><span class="span1">收件人在指定单据或回执上签字或盖章</span></div>
        <div class="flex4">
          <span class="span2">录入签收信息</span>
          <img src="../../assets/img/arrow1@3x.png" alt=""/>
        </div>
      </div>
      <div class="flex3"><img src="../../assets/img/slices/sign@2x.png" alt=""/></div>
    </div>
  </div>
</template>

<script>
import {Toast, Dialog, Button} from 'vant';
import NavBar from '../../components/NavBar/NavBar.vue'
// import AMap from 'AMap';
// import VConsole from 'vconsole'


// const vConsole = new VConsole({theme: 'dark'});

export default {
  components: {
    NavBar
  },
  data() {
    return {
      // api: '/tezhong/form',//正式路径拼接
      // api: '',//测试
      navInfo: {
        title:'运力监控',
        left:true,
        rigth:true,
        path:'/regularWaybill'
      },
      toast: undefined,
      show: false,
      form: {
        name: "",
        phone: "",
        carNo: "",
      },

      lng: '', //经度
      lat: '', //纬度
      title: this.$route.meta.title, //菜单表头
      waybillId: "", //正式

      orderId: "",//订单的ID(乐送)
      status: '4', //正式,
      orderStatus: 1,//如果有订单订单的状态 默认为1为了链接没有订单id时只要判断status  1：可以点击进去上传回执   0：订单已结束不可进入上传回执

      erpId:undefined,
      orderNo:undefined,
    };
  },
  created() {
    console.log(this.$route.query);
  },
  mounted() {
      console.log(this.waybillId,'11111')
      this.waybillId = this.$route.query.waybillId
      this.orderId = this.$route.query.orderId;
      this.erpId = this.$route.query.erpId || undefined;
      this.orderNo = this.$route.query.orderNo || undefined;

      if(this.erpId&&this.orderNo){
        this.getOrderNoInfo()
      }else{
        // this.getPoint();
        let newStr = this.changeNum(this.waybillId);
        this.waybillId = newStr;
        sessionStorage.setItem("waybillId", newStr)
        this.getWaybillIdStatus(this.waybillId);
        if (this.orderId) {
          sessionStorage.setItem("orderId", this.orderId);
          this.getOrderIdStatus(this.orderId);
        }
      }
  },
  methods: {
    //获取经纬度点
    // getPoint() {
    //   Toast.loading({
    //     message: '加载中...',
    //     forbidClick: true,
    //   });
    //   let that = this,
    //       map,
    //       geolocation;
    //   console.log(AMap);
    //   map = new AMap.Map('container', {
    //     resizeEnable: true
    //   });
    //   map.plugin('AMap.Geolocation', function () {
    //     geolocation = new AMap.Geolocation({
    //       enableHighAccuracy: true, //是否使用高精度定位，默认:true
    //       timeout: 10000, //超过10秒后停止定位，默认：无穷大
    //       buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
    //       zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
    //       buttonPosition: 'RB'
    //     });
    //     map.addControl(geolocation);
    //     geolocation.getCurrentPosition();
    //     AMap.event.addListener(geolocation, 'complete', that.onComplete); //返回定位信息
    //     AMap.event.addListener(geolocation, 'error', that.onError); //返回定位出错信息
    //   });
    // },
    // async getOrderNoInfo() {
    //   try {
    //     let rst = await this.$http.get(`${this.api}/lishenScanCode`, {params: {erpid: this.erpId, orderNo: this.orderNo}});
    //     if (rst.data.success) {
    //       let data = rst.data.data[0];
    //       this.$router.push({
    //         path: "/special/main", query: {
    //           waybillId: data.waybillId,
    //           orderId: data.orderId,
    //         }
    //       })
    //       location.reload();
    //     } else {
    //       // Toast.fail("该订单为无效订单");
    //       Dialog.confirm({
    //         title:'提示',
    //         message:'该订单暂时在SO56系统暂未派单，是否提前上报运输信息'
    //       }).then(()=>{
    //         this.$router.replace({
    //           path:"/special/prestore/main",
    //           query:{erpId:this.erpId,orderNo:this.orderNo,orderId:this.orderId}
    //         })
    //       }).catch(()=>{console.log("取消")})
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     Toast.fail("error")
    //   }
    // },
    //获取运单状态
    async getWaybillIdStatus(wId) {
      let res = await this.$Api.selectStatus({waybillId: wId})
      this.status = res.data.status
    },
    //获取订单状态
    async getOrderIdStatus(oId) {
      let res = await this.$Api.selectStatusByOrderId({orderid: oId})
      this.orderStatus = res.data.status;
    },
    //单号转换
    changeNum(str) {
      str = String(str);
      let newStr = '',
          that = this;
      for (let i = 0; i < str.length; i++) {
        if (str[i] == 'a') {
          newStr += '8';
        } else if (str[i] == 'b') {
          newStr += '3';
        } else if (str[i] == 'c') {
          newStr += '1';
        } else if (str[i] == 'd') {
          newStr += '4';
        } else if (str[i] == 'e') {
          newStr += '6';
        } else if (str[i] == 'f') {
          newStr += '7';
        } else if (str[i] == 'g') {
          newStr += '5';
        } else if (str[i] == 'h') {
          newStr += '9';
        } else if (str[i] == 'i') {
          newStr += '2';
        } else {
          newStr += str[i];
        }
      }
      return newStr;

    },
    //跳转录入信息页面  1--起运  2--在途  3--签收
    editInfo(data) {
      let that = this;
        this.$router.push({
          path: '/edit',
          query: {
          type: data,
          lng: that.lng,
          lat: that.lat,
          waybillId: that.waybillId,
          orderId: that.orderId,
          }
        });
    },
    //司机回退
    async back() {
      let res = await this.$http.get(`${this.api}/updateWayBillStatus`, {params: {waybillId: this.waybillId, orderId: this.orderId}})
      location.reload()
      // if(res.success) {
      //   this.getWaybillIdStatus(this.waybillId)
      // }
    },
    //解析定位结果
    onComplete(data) {
      console.log('可以获取定位');
      let that = this;
      that.lng = data.position.getLng();
      that.lat = data.position.getLat();
      Toast.clear();
    },
    //解析定位错误信息
    onError(data) {
      Toast("定位失败");
      // console.log('不可以获取定位');
      // setTimeout(() => {
      //   Toast(data);
      // }, 1000);
      // let that = this;
      // that.lng = '';
      // that.lat = '';
    },
  }
};
</script>

<style lang="scss" scoped>
  :deep .van-button--large {
    width: 90%;
    margin: 5% 5% 0 5%
  }
.selectGoods {
  font-size: 14px;
  width: 100%;
  height: 100%;

  .mint-header-out {
    //标题背景
    height: 50px;
    line-height: 50px;
    background-color: #06213e;

    .bg-blue {
      color: #fff;
      font-size: 18px;

      img {
        width: 35%;
        height: auto;
        vertical-align: middle;
      }
    }
  }

  .flex1 {
    height: 25%;
    width: 90%;
    margin: 5% 5% 0% 5%;

    background-image: url(../../assets/img/background@3x.png);
    background-repeat: no-repeat;
    background-size: 101% 101%;
    -moz-background-size: 101% 101%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;

    -webkit-box-shadow: #c3c5c7 0px 0px 10px;
    -moz-box-shadow: #c3c5c7 0px 0px 10px;
    box-shadow: #c3c5c7 0px 0px 10px;

    .flex3 {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .flex4 {
        height: 33.3%;
        width: 85%;
        text-align: left;
        padding-left: 20%;

        h2 {
          font-weight: bold;
        }

        .span1 {
          font-size: 14px;
          color: #999999;
        }

        .span2 {
          color: #fd5c0c;
          vertical-align: middle;
        }

        img {
          width: 5%;
          height: auto;
          vertical-align: middle;
          margin-left: 10%;
        }
      }

      img {
        margin: 10%;
        height: auto;
        width: 73%;
      }
    }
  }

  .flex1a {
    height: 25%;
    width: 90%;
    margin: 5% 5% 0% 5%;

    background-image: url(../../assets/img/slices/background@2x.png);
    background-repeat: no-repeat;
    background-size: 101% 101%;
    -moz-background-size: 101% 101%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;

    -webkit-box-shadow: #c3c5c7 0px 0px 10px;
    -moz-box-shadow: #c3c5c7 0px 0px 10px;
    box-shadow: #c3c5c7 0px 0px 10px;

    .flex3 {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .flex4 {
        height: 33.3%;
        width: 85%;
        text-align: left;
        padding-left: 20%;

        h2 {
          font-weight: bold;
          //color: ;
        }

        .span1 {
          font-size: 14px;
          color: #999999;
        }

        .span2 {
          color: #999999;
          vertical-align: middle;
        }

        img {
          width: 5%;
          height: auto;
          vertical-align: middle;
          margin-left: 10%;
        }
      }

      img {
        margin: 10%;
        height: auto;
        width: 73%;
      }
    }
  }

  .flex2 {
    height: 3%;
    width: 90%;
    margin: 2% 5% 5%;
    text-align: right;
    color: #d8d8d8;

    span {
      font-size: 15px;
      vertical-align: middle;
    }

    .img1 {
      width: 4%;
      height: auto;
      vertical-align: middle;
    }
  }

  .mint-header-out {
    //标题背景
    height: 50px;
    line-height: 50px;
    background-color: #06213e;

    .bg-blue {
      color: #fff;
      font-size: 18px;
    }
  }
}
</style>
